<template>
  <div>
    <b-row v-if="activitiesList.length > 0">
      <b-col
        cols="12"
        md="6"
        lg="3"
        v-for="(item, index) in activeListData"
        :key="index"
      >
        <activityCard :item="item" />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <EKPagination :items="activitiesList" v-model="activeListData" />
      </b-col>
    </b-row>
    
        <div v-else style='height:65vh' class="d-flex align-items-center justify-content-center">
          <h3>
            لايوجد نشاطات
          </h3>
        </div>
  </div>
</template>
<script>
import EKPagination from "@Ekcore/components/EK-pagination";
import activityCard from "./components/activity-card.vue";
import { mapActions, mapGetters } from "vuex";
import { handleDashLangChange } from "@/libs/event-bus";
export default {
  components: {
    EKPagination,
    activityCard,
  },
  data: () => ({
    activeListData: [],
  }),
  created() {
     this.fetchActivities();
     handleDashLangChange(this.fetchActivities);
  },
  computed: {
    ...mapGetters(["activitiesList"]),
  },
  methods: {
    ...mapActions(["fetchActivities"]),
  },
  beforeDestroy() {
    this.$store.commit("Reset_Search_Dto");
  },
};
</script>

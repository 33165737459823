<template>
  <b-card no-body class="overflow-hidden">
    <b-card-header class="p-1">
        <div>
            <h5>
                <strong>{{ item.title }}</strong>
            </h5>
            تاريخ الإضافة: <strong>{{ new Date(item.date).toLocaleDateString("en-UK") }}</strong>
        </div>
    </b-card-header>
    <b-card-body class="p-1">
      <img
        style="height: 160px; object-fit: cover"
        :src="
          item.documents[0]
            ? item.documents[0].path
              ? item.documents[0].path.replace(/^/ , $store.getters['app/domainHost'] + '/')
              : ''
            : ''
        "
        class="rounded w-100"
      />
      <div
        class="min-height-card"
        :inner-html.prop="item.description | truncate(100)"
      ></div>
    </b-card-body>
    <b-card-footer class="p-0">
      <b-button
        variant="primary"
        :to="'/admin/activities/' + item.id"
        block
        squared
        class="rounded-t-0"
        >عرض التفاصيل</b-button
      >
    </b-card-footer>
  </b-card>
</template>
<script>
export default {
  props: {
    item: Object,
  },
};
</script>
<style scoped>
.min-height-card {
  min-height: 20px;
}
</style>
